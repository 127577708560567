import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    customFieldId: String,
    origin: String,
    appGuid: String,
    instructions: String
  }

  connect() {
    console.log('connected');
    this.client = ZAFClient.init();

    this.setupOrderNumberListener();
  }

  setupOrderNumberListener() {
    let stimulus = this;

    console.log('setting up');
    if (!stimulus.customFieldIdValue) return;

    stimulus.client.on('ticket.custom_field_' + stimulus.customFieldIdValue + '.changed', (orderNumber) => {
      stimulus.client.get('ticket.requester').then(function(ticket) {
        const email = ticket['ticket.requester'].email;

        stimulus.redirect(email, orderNumber);
      });
    });
  }

  redirect(email, orderNumber) {
    const params = {
      email: email,
      number: orderNumber ?? '',
      origin: this.originValue,
      app_guid: this.appGuidValue
    }
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    window.location.replace('orders?' + queryString);
  }

  populateInstructions({ params }) {
    let stimulus = this;

    stimulus.client.get('ticket.requester').then((data) => {
      const message = params.instructions.replace('{{ticket.customer.firstname}}', data['ticket.requester'].name.split(' ')[0]);

      stimulus.client.set('comment.text', message)
      stimulus.client.invoke('ticket.collaborators.remove', { email: 'service@shippedapp.co' })
      stimulus.client.invoke('ticket.tags.add', ['shipped-shield'])
    })
  }

  loopInShippedSupport({ params }) {
    let stimulus = this;

    stimulus.client.get('ticket.requester').then((data) => {
      const message = params.instructions.replace('{{ticket.customer.firstname}}', data['ticket.requester'].name.split(' ')[0]);

      stimulus.client.set('comment.text', message)
      stimulus.client.invoke('ticket.tags.add', ['shipped-shield'])
      stimulus.client.invoke('ticket.collaborators.add', { email: 'service@shippedapp.co' })
    })
  }

  fetchOrders() {
    let stimulus = this;

    console.log('fetching orders');
    let promises = [this.client.get('ticket.requester'), this.client.get('ticket.customField:custom_field_' + stimulus.customFieldIdValue ?? '')];
    Promise.all(promises).then(function([ticket, customField]) {
      const email = ticket['ticket.requester'].email;
      const orderNumber = customField['ticket.customField:custom_field_' + stimulus.customFieldIdValue ?? ''];

      stimulus.redirect(email, orderNumber);
    });
  }
}
