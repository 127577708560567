import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    observeWidth: Boolean,
    querySelector: String,
    heightDelta: Number
  }

  cache = { "for": null }

  titles = []
  contents = []

  syncHeight() {
    const updateAccordionItems = () => {
      if (this.cache.for == this.element.clientWidth) return;
      this.contents.forEach(dd => {
        dd.style.setProperty('--height', (dd.scrollHeight + (this.heightDeltaValue ?? 0)) + 'px')
      })
      this.cache.for = this.element.clientWidth
    }
    if (this.observeWidthValue) {
      new ResizeObserver(updateAccordionItems).observe(this.element)
    }
    updateAccordionItems()
  }

  connect() {
    this.titles = this.element.querySelectorAll((this.querySelectorValue ?? '') + ' dt')
    this.contents = this.element.querySelectorAll((this.querySelectorValue ?? '') + ' dd')
    this.syncHeight()
    this.titles.forEach((title, i) => {
      title.addEventListener('click', () => {
        this.open(i)
      })
    })
    this.open(0)
  }

  open(index) {
    const title = this.titles[index]
    if (!title) return
    title.classList.toggle('open')
    this.titles.forEach((_, i) => {
      if (i != index) {
        this.close(i)
      }
    })
  }

  close(index) {
    const title = this.titles[index]
    if (!title) return
    title.classList.remove('open')
    const content = this.contents[index]
    content.style.maxHeight = null
  }

  disconnect() {
    this.cache.for = null
  }
}